import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import PythonWebImg from "../static/img/python-web.jpg";
import PythonIcon from "../static/img/python-icon.png";
import AccordionItem from "../component/Courses/AccordionItem";
import ContactForm from "../component/Courses/ContactForm";
import MetaTags from "../component/MetaTags";
import Price from "../component/Price";

const djangodevelopercourse = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  return (
    <>
      <Navbar />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <section id="courses-sec" className="course-banner">
              <div className="language-content">
                <h1 align="center">THE BEST ONLINE DJANGO COURSE</h1>
                  <p align="center">
                    {
                      "Our comprehensive Django course is designed to equip you with the necessary skills to become proficient in this powerful web development framework. Whether you're a beginner starting your coding journey or an experienced developer seeking to expand your skill set, our program is tailored to cater to learners of all levels."
                    }
                    <br />
                    {
                      "Join us today and unlock the potential of Django. Let's dive into the world of web development together and shape a promising future for your coding career!"
                    }
                  </p>
                  <Price buy={true} inUrl={'test_14k4kj3W6fOZeXK8wG'} usUrl={'test_5kA4kj2S27it16U3cn'} />
                </div>
              <div className="japanese-form">
                <ContactForm />
              </div>
        </section>

        <h3 className="courses__joinUs"></h3>
        <section id="courses__about" className="courses__about">
          <h3 className="light-text text-center">
            ABOUT <span className="highlighted-text">PYTHON ONLINE TRAINING</span> 
          </h3>
          <div className="container pt-4 d-flex flex-column gap-5">
            <div className="row">
              <div className="col-sm-6 d-flex justify-content-center">
                <img src={PythonWebImg} alt="" className="courses__about-img" />
              </div>
              <div className="col-sm-6 d-flex align-items-center mt-4 mt-md-0">
                <div className="courses__about__content">
                  <p>
                    {
                      "In our Django course, you will learn the fundamentals of Django, including creating dynamic web applications, implementing database models, handling user authentication, and much more. Our experienced instructors will guide you through hands-on projects and practical exercises, allowing you to gain real-world experience and apply your knowledge in a supportive learning environment."
                    }
                  </p>
                  <p>
                    {
                      "By the end of the program, you will have the confidence and skills to develop robust and scalable web applications using Django. Whether you aspire to work on exciting web development projects or build your own startup, our Django course will provide you with the foundation needed to succeed."
                    }
                  </p>
                  
                </div>
              </div>
            </div>

            <div className="row course-highlights">
              <h3 className="ms-3 font-weight-bolder">Program Highlights</h3>
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Learn Django from industry experts during a focused
                        one-month training program.
                      </span>
                    </p>
                  </div>{" "}
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Establish a solid groundwork in fundamental coding
                        principles.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Enhance problem-solving and analytical skills through
                        interactive learning.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Syllabus designed to align with industry requirements
                        and job market demands.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Receive assistance in finding internships that align
                        with your Python skills.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Beginner to expert level coaching to cater for learners
                        at all stages.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Obtain a valid certificate upon completion of the
                        course.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Access affordable fee options to make the course
                        accessible.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Benefit from a comprehensive curriculum covering various
                        Python topics.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Engage in an interactive learning environment with
                        active participation.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Gain career advancement opportunities through networking
                        and industry connections.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row course-syllabus">
              <h3 className="light-text text-center ">
                What <span className="highlighted-text">You Will Learn</span> 
              </h3>
              <div className="d-flex flex-column flex-lg-row gap-5 mt-4">
                <ul className="course-syllabus__list">
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 1: Getting Started"
                  >
                    <ul>
                      <li>Introduction to Django and its features</li>
                      <li>Setting up the Django development environment</li>
                      <li>Starting a New Project</li>
                      <li>Django Apps</li>
                      <li>understanding folder structure</li>
                      {/* <li>
                        Error handling and exceptions in Python programming
                      </li> */}
                    </ul>
                  </AccordionItem>
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 2: Django ORM (Object-Relational Mapping):"
                  >
                    <motion.ul>
                      <li>Models and database tables</li>
                      <li>Fields and data types</li>
                      <li>Relationships (One-to-One, One-to-Many, Many-to-Many)</li>
                      <li>Querying data using the ORM</li>
                      <li>Aggregation and filtering</li>
                      <li>Advanced query techniques (raw SQL, Q objects, complex lookups)</li>
                      <li>Model inheritance and abstract base classes</li>
                    </motion.ul>
                  </AccordionItem>
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 3: Django Forms: "
                  >
                    <ul>
                      <li>Form creation and validation.</li>
                      <li>Form rendering and handling</li>
                      <li>Field types and widgets</li>
                      <li>Formsets and inline formsets</li>
                      <li>Form customization and styling</li>
                      <li>File uploads and form handling</li>
                    </ul>
                  </AccordionItem>
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 4: Django Views and Templates:"
                  >
                    <ul>
                      <li>Class-based views and function-based views</li>
                      <li>Request and response handling</li>
                      <li>Template language and syntax</li>
                      <li>Template inheritance and context variables</li>
                      <li>Template tags and filters</li>
                      <li>Form handling in views</li>
                      <li>AJAX and API views</li>
                    </ul>
                  </AccordionItem>

                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 5: Django Authentication and Authorization:"
                  >
                    <ul>
                      <li>User authentication and registration</li>
                      <li>User login and logout views</li>
                      <li>Authentication middleware</li>
                      <li>User permissions and groups</li>
                      <li>Custom authentication backends</li>
                      <li>Social authentication (OAuth, OpenID)</li>
                    </ul>
                  </AccordionItem>

                  {/* <div>
                      Please note that this syllabus provides an overview of the topics covered in each week, and specific subtopics or additional concepts may be included based on the course requirements and duration.
                  </div> */}
                </ul>
                <img
                  src={PythonIcon}
                  style={{ justifySelf: "start" }}
                  alt=""
                  className=""
                />
              </div>
            </div>
          </div>
          <section
            id="services"
            className="advance"
            style={{ marginTop: "4rem" }}
          >
            <div className="container">
              <header className="section-header">
                <h3 className="light-text"> WHY <span className="highlighted-text"> KODERBOX TECHNOLOGIES?</span> </h3>
              </header>
              <br />
              <div className="">
                <div
                  className="col-md-12  wow bounceInUp "
                  data-wow-duration="1.4s"
                >
                  <div className="box blank_service">
                    <div className="row">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We believe that learning should be accessible to
                                everyone. Our course offers an affordable fee
                                structure, making quality Python education
                                within reach for aspiring learners.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We prioritize practical training to ensure you
                                acquire hands-on experience. Through real-world
                                projects, coding exercises, and interactive
                                sessions, you will gain the skills and
                                confidence needed to excel in React JS
                                development.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Our online python course covers a wide range of
                                Python topics, ensuring that you gain a holistic
                                understanding of the language and its
                                applications. You'll learn about variables, data
                                types, control flow, functions, object-oriented
                                programming, and more.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp mb-2"
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Choosing our online Python course opens up
                                career advancement opportunities. You'll have
                                the chance to network with professionals in the
                                industry, expanding your connections and opening
                                doors for future career prospects.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>

          {/* <section className="join container">
            <div className="join__layer">
              <div className="join__layer-1">
                <h1>
                  Take the first step towards a successful career in tech era
                  with us.
                </h1>
                <p>
                  Join our python Training program and find the potential to
                  learn dynamic and interactive framework using this powerful
                  language.
                </p>
              </div>
              <div className="join__layer-2">
                <h2>Contact</h2>
                <div className="join__layer-2--form-control">
                  <label for="full-name">Full Name</label>
                  <input type="text" id="full-name" name="full-name" />
                </div>
                <div className="join__layer-2--form-control">
                  <label for="email">Email</label>
                  <input type="email" id="email" name="email" />
                </div>
                <button>Contact Now</button> */}
                {/* <p className="text-xs text-gray-500 mt-3">
                  Literally you probably haven't heard of them jean shorts.
                </p> */}
              {/* </div>
            </div>
          </section> */}
        </section>
      </motion.div>
      <MetaTags title={"DJango | Koderbox"} />
      <Footer />
    </>
  );
};

export default djangodevelopercourse;
