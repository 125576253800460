import React, { useState } from "react";
import DownArrowIcon from "../../static/img/icons/down-arrow.png";
import { motion } from "framer-motion";
const AccordionItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li className={props.className}>
      <div className="d-flex gap-2">
        <span style={{ fontWeight: "500" }}>❉</span>
        <span
          style={{ fontWeight: "500", fontSize: "1.1rem" }}
          className="font-weight-bold"
        >
          {props.title}
        </span>
        <span className="">
          <button
            style={{ border: "none", background: "none" }}
            className="border-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              style={{
                height: "20px",
                width: "20px",
                transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "all 0.3s ease-in-out",
              }}
              src={DownArrowIcon}
              alt="arrow"
            />
          </button>
        </span>
      </div>
      <motion.div
        style={{ display: isOpen ? "block" : "none", transition: "all 0.3s" }}
        className="accordion__content mt-2"
      >
        {props.children}
      </motion.div>
    </li>
  );
};

export default AccordionItem;
