import React from "react";
import axios from 'axios';
import { url } from "../../url/url";
import { NotificationContainer, NotificationManager } from "react-notifications";

const ContactForm = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    axios({
      method: 'POST',
      url: url + '/contact-us/',
      data: {
        username: name,
        email: email,
        phone_no: phone,
        message: message
      }
    })
    .then((res) => {
      if (res.status === 200) {
      setName("")
      setEmail("")
      setPhone("")
      setMessage("")
       NotificationManager.success(" Message Has Sent ! ");
      }
    })
    .catch((err) => {
      NotificationManager.error(" Oops ! ");
    });
  };

  return (
    <div className="contact__card">
      <form onSubmit={handleSubmit} className="contact__card--form">
        <h5>Contact Us</h5>
        <div className="contact__card--form-control">
          {/* <label htmlFor="email">Your email</label> */}
          <input
            className={"form-control"}
            type="text"
            id="name"
            name="name"
            placeholder={"Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="contact__card--form-control">
          {/* <label htmlFor="email">Your email</label> */}
          <input
            className={"form-control"}
            type="email"
            id="email"
            name="email"
            placeholder="name@company.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="contact__card--form-control">
          {/* <label htmlFor="test">Your Phone</label> */}
          <input
            className={"form-control"}
            type="text"
            id="phone"
            minLength={10}
            maxLength={10}
            name="phone"
            placeholder="Contact No."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="contact__card--form-control">
          {/* <label htmlFor="message">Your message</label> */}
          <textarea
            className={"form-control"}
            id="message"
            rows="3"
            name="message"
            placeholder="Let us know how we can help you"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <button className={"btn btn-primary"} type="submit">Contact Now</button>
      </form>

      <NotificationContainer />
    </div>
  );
};

export default ContactForm;
