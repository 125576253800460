import React, { useState, useEffect } from "react";

const Price = ({ offerPrice, originalPrice, inr, usd, buy, inUrl, usUrl,id }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [inIndia, setInIndia] = useState(false);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const inIndia = checkInIndiaRange(latitude, longitude);

            setLatitude(latitude);
            setLongitude(longitude);
            setInIndia(inIndia);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

  // Function to check if the given coordinates are within the range of India
  const checkInIndiaRange = (lat, lng) => {
    const minLat = 8.4;
    const maxLat = 37.6;
    const minLng = 68.1;
    const maxLng = 97.4;

    return lat >= minLat && lat <= maxLat && lng >= minLng && lng <= maxLng;
  };

  return (
    <>
      {offerPrice && (
        <div className="discount-badge">
          Offer: {inIndia ? `₹${inr}` : `$${usd}`}
        </div>
      )}
      {originalPrice && <span>Price:{inIndia ? `₹${inr}` : `$${usd}`}</span>}
      {buy && <a href={`https://buy.stripe.com/${inIndia?inUrl:usUrl}`} target="_blank"><button className="pay-btn" align="center">Buy Now</button></a>}
    </>
  );
};

export default Price;
